import React from "react"
import Layout from "@components/layout"
import Heading from "@components/elements/heading"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"

const KlipfolioColoursPage = () => {
  return (
    <Layout
      title={`Klipfolio Colours`}
      description={`Guidelines & best practices for using Klipfolio's colours.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <Flex gap="6rem" gapSm="4rem">
          <Flex background="#f3f5fc">
            <Container>
              <Heading
                fontSize="3rem"
                as="h1"
                padding="12rem 0 8rem"
                paddingSm="8rem 0 4rem"
              >
                Klipfolio Colour Guidelines
              </Heading>
            </Container>
          </Flex>
          <Container>
            <Flex gap="8rem" gapSm="6rem" margin="0 0 6rem">
              <div>
                <Heading fontSize="3rem" as="h3" margin="0 0 4rem">
                  Logo Colours
                </Heading>
                <Grid gap="2rem" columns="repeat(3, 1fr)" columnsMd="1fr">
                  <Flex gap="2rem">
                    <Div
                      background="black"
                      padding="1rem 2rem"
                      borderRadius="1rem"
                    >
                      <Paragraph color="white">Logo Black</Paragraph>
                    </Div>
                    <Flex gap="0.5rem">
                      <Paragraph>
                        <b>HEX:</b> #000000
                      </Paragraph>
                      <Paragraph>
                        <b>CMYK:</b> C0 M0 Y0 K100
                      </Paragraph>
                      <Paragraph>
                        <b>PMS Coated:</b> Black 6C
                      </Paragraph>
                    </Flex>
                  </Flex>
                  <Flex gap="2rem">
                    <Div
                      background="#ed1c24"
                      padding="1rem 2rem"
                      borderRadius="1rem"
                    >
                      <Paragraph color="white">Logo Red</Paragraph>
                    </Div>
                    <Flex gap="0.5rem">
                      <Paragraph>
                        <b>HEX:</b> #ed1c24
                      </Paragraph>
                      <Paragraph>
                        <b>CMYK:</b> C0 M94 Y100 K0
                      </Paragraph>
                      <Paragraph>
                        <b>PMS Coated:</b> 1795
                      </Paragraph>
                    </Flex>
                  </Flex>
                  <Flex gap="2rem">
                    <Div
                      border="1px solid #eee"
                      padding="1rem 2rem"
                      borderRadius="1rem"
                    >
                      <Paragraph>Logo White</Paragraph>
                    </Div>
                    <Flex gap="0.5rem">
                      <Paragraph>
                        <b>HEX:</b> #ffffff
                      </Paragraph>
                      <Paragraph>
                        <b>CMYK:</b> C0 M0 Y0 K0
                      </Paragraph>
                      <Paragraph>
                        <b>PMS:</b> None
                      </Paragraph>
                    </Flex>
                  </Flex>
                </Grid>
              </div>
              <div>
                <Heading fontSize="3rem" as="h3" margin="0 0 4rem">
                  Brand Colours
                </Heading>
                <Grid
                  gap="2rem"
                  columns="repeat(5, 1fr)"
                  columnsMd="repeat(3, 1fr)"
                >
                  <Flex gap="2rem">
                    <Div
                      background="#e0f8ff"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#9ee6fa"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#5dd5f6"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#18aed8"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#1278a1"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#013950"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#012330"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                  </Flex>
                  <Flex gap="2rem">
                    <Div
                      background="#e8fde2"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#75e1b2"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#34ad78"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#00805d"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#014c33"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#002e1f"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#000f0a"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                  </Flex>
                  <Flex gap="2rem">
                    <Div
                      background="#fff5e0"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#ffb85c"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#f38338"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#d24414"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#ac2e1f"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#741e1f"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#180606"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                  </Flex>
                  <Flex gap="2rem">
                    <Div
                      background="#fae5ef"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#fda1b9"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#fb6987"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#e41b50"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#b8143e"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#730d23"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#29050d"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                  </Flex>
                  <Flex gap="2rem">
                    <Div
                      background="#edf3fd"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#b0b8fc"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#707efa"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#4b57c5"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#343c8d"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#21264f"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                    <Div
                      background="#0f1124"
                      padding="3rem"
                      borderRadius="1rem"
                    />
                  </Flex>
                </Grid>
              </div>
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Layout>
  )
}

export default KlipfolioColoursPage
